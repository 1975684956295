
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import { getRemoteApiUrl } from '@/components/view/pm/device-info-setting-dialog/utils';
import HttpRequest from '@/util/axios.config';
import { propertyBaseRouter, propertySubRouter } from '@/router';
import { community, device } from '@/data';
import JumpPage from '@/components/view/common/jump-page/index.vue';
import deviceInfoSettingDialog from '@/components/view/pm/device-info-setting-dialog/index';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb/index';
import { DeviceInfoItemData, DeviceInfoData, DeviceInfoKey } from '../types/all.type';
import { getProjectData } from '../composables/util';

const titleType = 1;
const labelType = 2;
const labelCustomize = 3;
const publicType = 4;

export default defineComponent({
    components: {
        propertyBreadCrumb,
        deviceInfoSettingDialog,
        JumpPage
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const breadCrumbHeaders = [
            {
                label: WordList.OrderDevice,
                path: `/${propertyBaseRouter}/${propertySubRouter.device}`
            },
            {
                label: WordList.PersonuserInfo
            }
        ];
        const deviceType: Ref<{ [key in number]: string }> = ref({});
        const IsShowBuildNames = ref(false);
        const detailForm: Ref<Array<DeviceInfoItemData>> = ref([]);
        const deviceDetail: Ref<DeviceInfoData> = ref<DeviceInfoData>({
            Basic: '',
            BuildNames: [],
            UnitName: '',
            RoomName: '',
            Location: '',
            MAC: '',
            SipAccount: '',
            LastConnection: '',
            Grade: '1',
            Owner: '',
            Product: '',
            Firmware: '',
            Hardware: '',
            Network: '',
            IPAddress: '',
            SubnetMask: '',
            Gateway: '',
            PrimaryDNS: '',
            ProjectType: '',
            SecondaryDNS: '',
            IsAllBuild: 0,
            IsAllDepartment: 0
        });
        const projectType = ref('');

        const isShowJumpPage = ref(false);
        const jumpUrl = ref('');
        const remote = () => {
            const url = getRemoteApiUrl(projectType.value);
            HttpRequest.post(
                url,
                {
                    MAC: deviceDetail.value.MAC
                },
                [
                    (res: any) => {
                        const newPage = window.open(`${res.url}`);
                        if (newPage === null) {
                            jumpUrl.value = res.url;
                            isShowJumpPage.value = true;
                        }
                    },
                    false
                ]
            );
        };

        const getPMDeviceInfo = () => {
            device.getPMDeviceInfo(props.id, (res: DeviceInfoData) => {
                deviceDetail.value = { ...res };
                if ((deviceDetail.value as DeviceInfoData).Grade === '1') {
                    IsShowBuildNames.value = true;
                }
                detailForm.value.forEach((key, index: number) => {
                    const ele = detailForm.value[index].name as DeviceInfoKey;
                    if (detailForm.value[index].type === labelCustomize) {
                        detailForm.value[index].text = deviceType.value[Number(deviceDetail.value[ele])];
                    } else if (detailForm.value[index].type === publicType) {
                        if (deviceDetail.value.IsAllBuild === 1 || deviceDetail.value.IsAllDepartment === 1) {
                            detailForm.value[index].text = community.ProjectType.value === 'community'
                                ? WordList.AllBuildings
                                : WordList.AllDepartments;
                        } else {
                            detailForm.value[index].text = (deviceDetail.value[ele] as Array<string>).join(';');
                        }
                    } else {
                        detailForm.value[index].text = deviceDetail.value[ele] as string;
                    }
                });
                projectType.value = deviceDetail.value.ProjectType;
            });
        };
        watch(
            community.ProjectType,
            () => {
                const projectData = getProjectData()[community.ProjectType.value];
                detailForm.value = projectData.detailForm;
                deviceType.value = projectData.deviceType;
                getPMDeviceInfo();
            },
            {
                immediate: true
            }
        );

        const isShowDeviceInfoSettingDialog = ref(false);
        const closeInfo = () => {
            isShowDeviceInfoSettingDialog.value = false;
            getPMDeviceInfo();
        };
        return {
            projectType,
            breadCrumbHeaders,
            detailForm,
            titleType,
            labelType,
            publicType,
            labelCustomize,
            IsShowBuildNames,
            deviceDetail,
            isShowDeviceInfoSettingDialog,
            closeInfo,
            remote,
            isShowJumpPage,
            jumpUrl
        };
    }
});
